import React from 'react';

const Head = ({
                  image = '/images/TechnologyBg.webp', // Default image URL
                  heading = '// The Good Coder', // Default heading text
                  subheading = 'Doing Good: One App At A Time', // Default subheading text
                  headingColor = 'text-white', // Default heading color
                  subheadingColor = 'text-white', // Default subheading color
                  headingSize = 'text-4xl', // Default heading size
                  subheadingSize = 'text-lg', // Default subheading size
                  padding = 'p-8', // Default padding
                  backgroundPosition = 'center', // Default background position
                  icon = '', // Default icon
                  maxHeight = '300',
                  buttonText = '', // Default button text
                  buttonLink = '' // Default button link
              }) => {
    return (
        <div
            className={`p-3 relative ${padding} bg-cover bg-center flex flex-col justify-center items-center`}
            style={image ? { backgroundImage: `url(${image})`, backgroundPosition } : {}}
        >
            {/* Content */}
            <div className="relative z-10 text-center">
                {icon && (
                    <img
                        src={icon}
                        alt={`${heading} icon`}
                        className="mb-4 mx-auto w-40 h-40"
                    />
                )} {/* Centered Icon */}
                <h3 className={`${headingSize} ${headingColor} mb-4 text-shadow`}>
                    {heading}
                </h3>
                <p className={`${subheadingSize} ${subheadingColor} text-shadow`}>
                    {subheading}
                </p>
                {buttonText && buttonLink && (
                    <a href={buttonLink} target="_blank" rel="noopener noreferrer">
                        <button
                            className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition duration-300"
                        >
                            {buttonText}
                        </button>
                    </a>
                )}
            </div>
        </div>
    );
};

export default Head;
