import React from 'react';
import {Helmet} from "react-helmet";
import Head from "../../../components/controls/Head";
import UniqueSellingPoint from "../../../components/home/UniqueSellingPoint";
import ServicesSection from "../../../components/home/ServicesSection";
import Partnerships from "../../../components/home/Partnerships";
import Header from "../../../components/controls/Header";

const SoftwareDevelopmentLanding = () => {
    return (
        <>
            <div className="w-full">
                <Head
                    heading="Building Innovative Software Solutions That Elevate Your Business"
                    subheading="We specialize in helping businesses streamline operations, automate processes, and scale effectively with custom solutions."
                    headingColor="text-white"
                    subheadingColor="text-white "
                    headingSize="text-5xl"
                    subheadingSize="text-xl"
                    padding="py-32"
                    backgroundPosition="top center"
                    buttonText="Learn More"
                    buttonLink="/contact"
                    icon="/icons/creativity.png"
                />
            </div>

            <Helmet>
                <title>Software Development // Good coder</title>
                <meta name="description" content="Home // Good coder"/>
            </Helmet>

            {/* Unique Selling Proposition (USP) */}

            <section className="m-0 md:p-10 sm:p-3 pt-10 pb-10 relative bg-cover bg-center">
                <div className="container mx-auto px-6">
                    <div className="grid md:grid-cols-2 gap-12">
                        <div>
                            <Header level="h2">// Partner with The Good Coder Today</Header>
                            <Header padding="pb-3 pt-3" level="h3">Doing Good</Header>
                            <p className="text-lg text-gray-300">We assist in making a positive impact in the world</p>

                            <Header padding="pb-3 pt-3" level="h3">Reduce Operational Costs</Header>
                            <p className="text-lg text-gray-300">Our solutions help drive businesses forward through
                                streamlining processes</p>

                            <Header padding="pb-3 pt-3" level="h3">Increase Efficiency</Header>
                            <p className="text-lg text-gray-300">We assist in automating repetitive tasks allowing your
                                team
                                to save time and focus on high-impact work.</p>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                            <img src="/images/ProfilePic.png" alt="Marshi Vision"
                                 className="mx-auto mb-8 rounded-md max-w-80"/>
                            <p className="text-sm text-gray-300">Louis Barnard - Owner</p>

                        </div>
                    </div>
                </div>
            </section>

            
            {/*<ImageList clientLogos={clientLogos} heading="Trusted by Leading Companies"/>*/}
            
            {/*<CaseStudies/>*/}
            
            {/*/!* Call-to-Action (CTA) *!/*/}
            {/*<Quote*/}
            {/*    title="Ready to Make a Difference?"*/}
            {/*    description="Discover how our altruistic approach to software development can benefit your company and help you create lasting change."*/}
            {/*    buttonText="Schedule a Consultation"*/}
            {/*    buttonLink="/contact"*/}
            {/*/>*/}
            
            {/*<ImageList clientLogos={techLogos} gridCols="grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-12"*/}
            {/*           heading="The right technology for the job" swap={true}/>*/}
        </>
    );
};

export default SoftwareDevelopmentLanding;
